import React from "react";
import "./WatchWindow.css";

export default function WatchWindow() {
  return (
    <div className="watch-window__container">
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/J2eG6_-TuHE?si=kvBvVdCmtkbbDmWH"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <a href="https://www.youtube.com/watch?v=PIrO7TOiGkk&list=PL3kIEwujEYkrY1CQpySqMdMgYXlCs628F" target="_blank" rel="noopener noreferrer">
        <p style={{ color: "red" }}>MORE VIDEOS</p>
      </a>
    </div>
  );
}
